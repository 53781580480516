import { Box, Dialog, Typography } from '@mui/material';
import CollectionDialogContent from 'common/components/Dialogs/CollectionDialogContent/CollectionDialogContent';
import DialogActionTitle from 'common/components/Dialogs/DialogActionTitle';
import DocsCollection from 'containers/Docs/DocsCollection';
import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { RetrievalUnitData } from 'containers/RetrievalUnit/RetrievalUnitData.interface';
import { DocCardComposition } from 'containers/User/User.enum';
import React, { FC } from 'react';

interface EvidenceDialogProps {
  answerContent: React.ReactNode;
  dialogOpen: boolean;
  handleClose: () => void;
  docsData: RetrievalUnitData[];
  organizeDocIds: string[];
  cardComposition?: DocCardComposition;
}

export const EvidenceDialog: FC<EvidenceDialogProps> = ({
  answerContent,
  dialogOpen,
  handleClose,
  docsData,
  organizeDocIds,
  cardComposition,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={dialogOpen}
      onClose={handleClose}
      aria-label="Evidences for the current chat answer"
    >
      <DialogActionTitle onClose={handleClose}>
        <Typography variant="body2">{answerContent}</Typography>
      </DialogActionTitle>

      <CollectionDialogContent dividers>
        {docsData && docsData.length > 0 && (
          <DocsCollection organizeDocIds={organizeDocIds}>
            {docsData.map((doc, i) => (
              <Box key={doc.document.id}>
                <RetrievalUnit
                  data={doc}
                  key={doc.document.id}
                  evidenceNumber={i + 1}
                  withImage={true}
                  cardComposition={cardComposition}
                  isEvidence={true}
                />
              </Box>
            ))}
          </DocsCollection>
        )}
      </CollectionDialogContent>
    </Dialog>
  );
};
