import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Theme, Tooltip, useTheme } from '@mui/material';
import { ChatMessageSenderEnum } from '@zarn/vendor/dist/search';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { Message } from './Message';
import { MessageInput } from './MessageInput';
import { SavingStatus } from './SavingStatus/SavingStatus';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { LoaderContainer } from 'common/components/Loaders/LoaderContainer/LoaderContainer';
import { useChat } from './hooks/useChat';
import { useChatMessages } from './hooks/useChatMessages';
import { useSelector } from 'react-redux';
import { selectUser } from 'containers/User/user.slice';
import { ConversationList } from './Conversation/ConversationList';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { ChatContext, Evidence } from 'api/chatApi/chatApi.types';
import {
  BotType,
  DefaultBotTypes,
} from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { Greetings } from './Greetings/Greetings';
import { ModeButtons } from './ModeButtons';
import { BoxWithoutScroll } from 'common/components/WithoutScroll/WithoutScroll';
import { Conversation } from './Chat.types';
import { useHistory } from 'react-router-dom';

export interface ChatProps<T extends object = {}> {
  predefinedConversation?: Conversation<T> | null;
  context: ChatContext;
  onSave: (
    content: string,
    note: NoteDetails | { content: string } | null
  ) => Promise<NoteDetails>;
  isSaving: boolean;
  isLoading?: boolean;
  onEvidenceChunkClick?: (chunks: ChunkHighlight[]) => void;
  defaultBotType: DefaultBotTypes;
  supportedBotTypes: BotType[];
  disabled?: boolean;
  greetings?: ReactNode;
  botParams?: Record<string, any>;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  placeholder?: string;
  header?: ReactNode;
  renderContextAction?: (conversation?: Conversation<T>) => ReactNode;
  isEvidenceDialog?: boolean;
  isDocCardInEvidence?: boolean;
}

export const Chat = <T extends object = {}>({
  predefinedConversation,
  context,
  isSaving,
  isLoading,
  defaultBotType,
  supportedBotTypes,
  disabled,
  greetings,
  botParams,
  onEvidenceChunkClick,
  onSave,
  onEvidenceItemClick,
  placeholder,
  header,
  renderContextAction,
  isEvidenceDialog,
  isDocCardInEvidence,
}: ChatProps<T>) => {
  const { t } = useTranslation('common');
  const { palette } = useTheme<Theme>();
  const chatMessagesRef = useRef<HTMLDivElement | null>(null);
  const { buildDefaultMessage } = useChatMessages();
  const user = useSelector(selectUser);

  const {
    conversation,
    isLoadingNote,
    isLoadingMessage,
    error,
    onSendMessage,
    onReset,
  } = useChat({
    predefinedConversation,
    context,
    botParams,
    onSave,
  });
  const isError = !!error;

  const scrollTo = useCallback((top: number) => {
    chatMessagesRef.current?.scroll({
      top,
      behavior: 'smooth',
    });
  }, []);

  const scrollToBottom = useCallback(
    (scrollHeight: number) => {
      scrollTo(scrollHeight);
    },
    [scrollTo]
  );

  useEffect(() => {
    if (chatMessagesRef.current) {
      scrollToBottom(chatMessagesRef.current.scrollHeight);
    }
  }, [conversation, isLoadingNote, scrollToBottom]);

  const handleSubmit = useCallback(
    (message: string) =>
      onSendMessage(buildDefaultMessage(message, defaultBotType)),
    [buildDefaultMessage, onSendMessage, defaultBotType]
  );

  const history = useHistory();
  const isCurrentPagePDFOrTags = !!(
    history.location.pathname.includes('/pdf') ||
    history.location.pathname.includes('/tags')
  );

  const isNewChatDisabled =
    isLoadingMessage ||
    isLoadingNote ||
    !conversation ||
    !isCurrentPagePDFOrTags;
  const isChatDisabled =
    disabled || isLoadingMessage || isLoadingNote || isSaving || isLoading;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      {header}
      <LoaderContainer loading={isLoadingNote}>
        {(greetings || !conversation) && (
          <Greetings isLoading={isLoading} greetings={greetings} />
        )}

        {!conversation && (
          <ModeButtons
            onClick={onSendMessage}
            supportedBotTypes={supportedBotTypes}
            disabled={disabled}
          />
        )}

        {conversation && (
          <>
            <BoxWithoutScroll
              sx={{ height: '100%', overflow: 'hidden scroll', mt: 2 }}
              ref={chatMessagesRef}
            >
              {renderContextAction?.(conversation)}

              <ConversationList
                conversation={conversation}
                initials={user?.initials}
                onEvidenceChunkClick={onEvidenceChunkClick}
                onEvidenceItemClick={onEvidenceItemClick}
                isEvidenceDialog={isEvidenceDialog}
                isDocCardInEvidence={isDocCardInEvidence}
              />

              {isError && (
                <Message
                  sender={ChatMessageSenderEnum.Bot}
                  isContent={true}
                  sx={{ backgroundColor: palette.error.light }}
                >
                  <Tooltip title={error?.message}>
                    {t('chat.message.error')}
                  </Tooltip>
                </Message>
              )}

              {isLoadingMessage && (
                <Message isContent={true} sender={ChatMessageSenderEnum.Bot}>
                  <CircularProgress
                    data-testid="loading-icon"
                    size={18}
                    color="secondary"
                  />
                </Message>
              )}
            </BoxWithoutScroll>

            <SavingStatus isSaving={isSaving} />
          </>
        )}
      </LoaderContainer>

      <Box
        style={{
          display: 'flex',
          padding: '0rem 0.5rem 1rem 0.5rem',
          alignItems: 'center',
          gap: '0.25rem',
        }}
      >
        <Tooltip title={t('chat.moreMenu.start')}>
          <Box>
            <TrackedIconButton
              onClick={onReset}
              data-testid="new-chat-button"
              disabled={isNewChatDisabled}
            >
              <AddCommentIcon />
            </TrackedIconButton>
          </Box>
        </Tooltip>

        <MessageInput
          disabled={isChatDisabled}
          isLoading={isLoadingMessage || isLoading}
          onSubmit={handleSubmit}
          placeholder={placeholder}
        />
      </Box>
    </Box>
  );
};
