import { useCallback, useEffect } from 'react';
import { useLoadChatNote } from './useLoadChatNote';
import { useSendChatMessage } from './useSendChatMessage';
import { BotTypeMessage } from 'api/tenantSettingsApi/tenantSettingsApi.types';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useChatMessages } from './useChatMessages';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { useChatConversation } from './useConversation/useChatConversation';
import { NoteDetails } from 'api/notesApi/notesApi.types';
import { ChatContext } from 'api/chatApi/chatApi.types';
import { Conversation } from '../Chat.types';
import { useQuerySearchParams } from 'common/hooks/useQuerySearchParams';

interface UseChatProps<T extends object = {}> {
  predefinedConversation?: Conversation<T> | null;
  context: ChatContext;
  botParams?: Record<string, any>;
  onSave: (
    content: string,
    note: NoteDetails | { content: string } | null
  ) => Promise<NoteDetails>;
}

export const useChat = <T extends object = {}>({
  context,
  botParams,
  onSave,
  predefinedConversation,
}: UseChatProps<T>) => {
  const { replace } = useNavigationWithState();

  const { note, conversation, onNoteChange, onConversationChange, onReset } =
    useChatConversation<T>(predefinedConversation);

  const { isLoading: isLoadingNote } = useLoadChatNote({
    onNoteLoaded: onNoteChange,
  });

  const {
    sendMessage,
    isLoading: isLoadingMessage,
    error,
  } = useSendChatMessage({
    context,
    conversation,
    botParams,
    onConversationChange,
  });

  const { buildExplainMessage, buildExplainImageMessage } = useChatMessages();

  const { setParams } = useQuerySearchParams();

  const handleReset = useCallback(() => {
    onReset();

    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    params.delete('noteId');

    url.search = params.toString();

    replace(`${location.pathname}${url.search}`);
    setParams({
      tab: 'chat',
    });
  }, [onReset, replace, setParams]);

  const onSendMessage = useCallback(
    async (botMessage: BotTypeMessage) => {
      const updatedConversation = await sendMessage(botMessage);
      if (updatedConversation) {
        const newConversation = await onConversationChange(updatedConversation);
        if (newConversation) {
          const { content } = newConversation;
          const newNote = await onSave(content, note);
          if (!note || !('id' in note) || newNote.id !== note.id) {
            onNoteChange(newNote);
          }
        }
      }
    },
    [note, onConversationChange, onNoteChange, onSave, sendMessage]
  );

  const { explainText } = useLocationParams('explainText');
  const { noteId } = useLocationParams('noteId');

  const { start } = useLocationParams('start');
  const { buildTagOnboardingMessage } = useChatMessages();

  useEffect(() => {
    if (
      !start ||
      start !== 'empty-tag' ||
      conversation ||
      isLoadingMessage ||
      error
    ) {
      return;
    }
    replace(`${location.pathname}?tab=chat`);
    void onSendMessage(buildTagOnboardingMessage());
  }, [
    buildTagOnboardingMessage,
    conversation,
    error,
    isLoadingMessage,
    onSendMessage,
    replace,
    start,
  ]);

  useEffect(() => {
    if (explainText) {
      void onConversationChange(null);
      const isImageHighlight = explainText.includes('data:image/png;base64');
      const noteIdForURL = noteId ? `&noteId=${noteId}` : '';
      replace(`${location.pathname}?tab=chat${noteIdForURL}`);

      const message = isImageHighlight
        ? buildExplainImageMessage(explainText)
        : buildExplainMessage(explainText);

      void onSendMessage(message);
    }
  }, [
    buildExplainMessage,
    explainText,
    replace,
    onSendMessage,
    buildExplainImageMessage,
    onNoteChange,
    onConversationChange,
    noteId,
  ]);

  return {
    conversation,
    isLoadingNote,
    isLoadingMessage,
    error,
    onSendMessage,
    onReset: handleReset,
    note,
  };
};
