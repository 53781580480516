import React, { FC, useMemo } from 'react';
import {
  ChatMessageElement,
  Evidence,
  FunctionCallProperties,
} from 'api/chatApi/chatApi.types';
import { Message } from '../Message';
import { MarkdownText } from 'common/components/Markdown/MarkdownText';
import { Parser } from '../hooks/useConversation/parsers/Parser';
import { EvidenceList } from '../Evidence/EvidenceList';
import { ChunkHighlight } from 'common/interfaces/ChunkHighlight.interfaces';
import { useNavigationWithState } from 'common/hooks/useNavigationWithState';
import { defined } from 'common/utils/assert';
import { useLocationParams } from 'common/hooks/useLocationParams';
import { useTranslation } from 'react-i18next';
import TrackedButton from 'common/components/Buttons/TrackedButton/TrackedButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import NoteAnnotationHighlightItem from 'common/components/Notes/NoteAnnotationHighlightItem';
import { DocCardEvidenceList } from '../Evidence/DocCardEvidenceList';
export interface ConversationMessageProps {
  message: ChatMessageElement;
  initials?: string;
  onEvidenceChunkClick?: (chunks: ChunkHighlight[]) => void;
  onEvidenceItemClick?: (evidence: Evidence) => void;
  isEvidenceDialog?: boolean;
  isDocCardInEvidence?: boolean;
}

export const ConversationMessage: FC<ConversationMessageProps> = ({
  message,
  initials,
  onEvidenceChunkClick,
  onEvidenceItemClick,
  isEvidenceDialog,
  isDocCardInEvidence,
}) => {
  const { t } = useTranslation(['chat']);

  const content = useMemo(() => Parser.prepareMessage(message), [message]);
  const { noteId } = useLocationParams('noteId');
  const { push } = useNavigationWithState();

  const openSuggestedDocsModal = (
    functionCallRequest: FunctionCallProperties
  ) => {
    push(`?tab=chat&noteId=${noteId}&fc=${functionCallRequest.name}`, {
      functionCallParams: JSON.stringify(functionCallRequest.params),
    });
  };

  return (
    <Message
      sender={message.sender}
      initials={initials}
      isContent={!!content}
      extra={
        message.evidences ? (
          isDocCardInEvidence ? (
            <DocCardEvidenceList items={message.evidences} />
          ) : (
            <EvidenceList
              items={message.evidences}
              onEvidenceChunkClick={onEvidenceChunkClick}
              onEvidenceItemClick={onEvidenceItemClick}
              isEvidenceDialog={isEvidenceDialog}
              answerContent={
                <>
                  <MarkdownText>{content}</MarkdownText>
                  {message.image && (
                    <NoteAnnotationHighlightItem
                      contentType={'image'}
                      contentBody={message.image}
                    />
                  )}
                </>
              }
            />
          )
        ) : null
      }
      button={
        message.functionCallRequest ? (
          <TrackedButton
            onClick={() =>
              openSuggestedDocsModal(defined(message.functionCallRequest))
            }
            eventName={TrackEventName.TagOnboardingSuggestedDocsClicked}
          >
            {t(`message.functionButton.label`)}
          </TrackedButton>
        ) : null
      }
    >
      <MarkdownText>{content}</MarkdownText>
      {message.image && (
        <NoteAnnotationHighlightItem
          contentType={'image'}
          contentBody={message.image}
        />
      )}
    </Message>
  );
};
