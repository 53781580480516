import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, Collapse, Divider, Tooltip } from '@mui/material';
import TrackedIconButton from 'common/components/Buttons/TrackedIconButton/TrackedIconButton';
import { TrackEventName } from 'common/components/TrackedActions/withTrackedAction.interface';
import { Evidence } from 'api/chatApi/chatApi.types';
import { useTranslation } from 'react-i18next';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useAllEvidenceHits } from 'containers/SearchWidgets/QuestionAnsweringWidget/hooks/useAllEvidenceHits';
import { useOrganizeDocIds } from 'containers/Docs/hooks/useOrganizeDocIds';

import RetrievalUnit from 'containers/RetrievalUnit/RetrievalUnit';
import { DocCardComposition } from 'containers/User/User.enum';
import DocsCollection from 'containers/Docs/DocsCollection';

interface DocCardEvidenceListProps {
  items: Evidence[];
}

export const DocCardEvidenceList: FC<DocCardEvidenceListProps> = ({
  items,
}) => {
  const { t } = useTranslation('common');
  const [isExpanded, setIsExpanded] = useState(false);
  const evidenceRef = useRef<HTMLDivElement | null>(null);
  const { allHits: docsData } = useAllEvidenceHits({
    evidences: items || [],
  });
  const organizeDocIds = useOrganizeDocIds(docsData);

  const toggleEvidence = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (evidenceRef.current) {
      setTimeout(
        () => evidenceRef.current?.scrollIntoView({ behavior: 'smooth' }),
        500
      );
    }
  }, [isExpanded]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '0.25rem',
        }}
      >
        <Tooltip title={t('chat.evidence.tooltip')}>
          <TrackedIconButton
            size="small"
            aria-label="show evidence"
            onClick={toggleEvidence}
            eventName={TrackEventName.ChatEvidenceClicked}
          >
            <LibraryBooksIcon />
          </TrackedIconButton>
        </Tooltip>
      </Box>
      <Collapse unmountOnExit timeout={400} in={isExpanded}>
        <Divider />
        <div ref={evidenceRef}>
          <DocsCollection organizeDocIds={organizeDocIds}>
            {docsData.map((doc, i) => (
              <Box key={doc.document.id}>
                <RetrievalUnit
                  data={doc}
                  key={doc.document.id}
                  evidenceNumber={i + 1}
                  withImage={false}
                  cardComposition={DocCardComposition.Evidence}
                  isEvidence={true}
                />
              </Box>
            ))}
          </DocsCollection>
        </div>
      </Collapse>
    </>
  );
};
